<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{title}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

         <!-- start service single area -->
        <div v-if="title" class="service-single-area pt-70 pb-100">

            <section id="about" class="about-section about-section-single">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div :style="{backgroundImage: 'url('+image2+')'}" class="image-content mb-30">
                                <img :src="image2" class="img1">
                                <img :src="image" class="img2 invisible">
                                <div class="count invisible">
                                    <h2>{{years}}</h2>
                                    <span>{{extra_text}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div v-html="description" class="about-content mb-30">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <!-- start services section -->
            <section id="services" class="service-section-three">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-12 ">
                            <aside class="widget-area">
                                <div class="widget">
                                    <h3 class="title">{{$t("subsidaries")}}</h3>
                                    <ul class="nav-list">
                                        <li v-for="(data, index) in child_companies" :key="index" :class="child_div_id == data.id ? 'active' : ''">
                                            <a href="javascript:void(0)" @click="getChildCompany(data.id)">{{data.title}}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget brochure d-none">
                                    <h3 class="title">{{$t("corporate_profile")}}</h3>
                                    <div v-if="file_pdf" class="content-list">
                                        <a :href="generateDownloadURL(file_pdf, title)" class="btn-block">
                                            <i class='bx bxs-file-pdf'></i>
                                            {{$t("download_pdf")}}
                                        </a>
                                    </div>
                                    <div v-if="file_doc" class="content-list">
                                        <a :href="generateDownloadURL(file_doc, title)" class="btn-block">
                                            <i class='bx bxs-file-doc' ></i>
                                            {{$t("download_doc")}}
                                        </a>
                                    </div>
                                    <div v-if="file_txt" class="content-list">
                                        <a :href="generateDownloadURL(file_txt, title)" class="btn-block">
                                            <i class='bx bxs-file-txt'></i>
                                            {{$t("download_txt")}}
                                        </a>
                                    </div>                                 
                                </div>
                            </aside>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="service-details-desc">
                                <div class="service-details-slider">
                                    <div class="image">
                                        <img :src="child_company_details.banner_image">
                                    </div>
                                </div>
                                <div class="content">
                                    <h2>{{child_company_details.title}}</h2>
                                    <p v-html="child_company_details.description">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'ChildCompanies',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),

        getChildCompany(id){
            axios.get(`child-company/${id}`)
            .then(response => {
                console.log(response)
                if(response.status == 200){
                    this.child_div_id = response.data.data.id
                    this.child_company_details = response.data.data
                }
            })
            .catch(e => {
            })
        },

        generateDownloadURL(url, label) {
            let ext = url.split('.').pop()
            let baseUrl = `${process.env.VUE_APP_BASE_URL}download?`
            return `${baseUrl}url=${url}&label="${label}.${ext}`
        }
    },

    data() {
        return {
            parent_id: this.$route.params.id,
            child_companies: [],
            child_company_details: [],
            banner_image: '',
            image: '',
            title: '',
            description: '',
            child_div_id: ''
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('our_companies')
        
        axios.get(`parent-company/${this.parent_id}`)
        .then(response => {
            this.banner_image = response.data.data.banner_image
            this.child_companies = response.data.data.child_companies

            if(this.child_companies.length > 0){
                this.child_div_id = this.child_companies[0].id
                this.getChildCompany(this.child_div_id)
            }

            this.image = response.data.data.image
            this.image2 = response.data.data.image2
            this.extra_text = response.data.data.extra_text
            this.years = response.data.data.years
            this.title = response.data.data.title
            this.description = response.data.data.description
            this.file_doc = response.data.data.file_doc
            this.file_pdf = response.data.data.file_pdf
            this.file_txt = response.data.data.file_txt
            
        })
        .catch(e => {
        })
    }
}
</script>