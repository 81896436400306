<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('our_projects')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start projects single area -->
        <div class="project-single-area ptb-100">
            <!-- start projects section -->
            <section id="projects" class="projects-section-three">
                <div class="container">
                    <div class="row filter-container">
                        <div v-for="(data, index) in content" :key="index" class="filtr-item col-lg-4 col-md-6" >
                            <div class="item-single mb-2">
                                <div class="image">
                                    <img :src="data.image" :alt="data.title">
                                </div>
                                <div class="content">
                                    <h3><a href="javascript:void(0)" @click="$router.push('/project/'+data.id)">{{data.sub_title}}</a></h3>
                                    <a href="javascript:void(0)" @click="$router.push('/project/'+data.id)">{{$t('view_details')}}</a>
                                </div>
                            </div>
                            <h6 class="mb-30" ><a href="javascript:void(0)" @click="$router.push('/project/'+data.id)">{{data.title}}</a></h6>
                        </div>
                    </div>
                </div>
            </section>
            <!-- end projects section -->
        </div>
        <!-- end projects single area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'Projects',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },
    data() {
        return {
            content: [],
            banner_image: ''
        }
    },
    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('our_projects')

        axios.get(`projects`)
        .then(response => {
            this.content = response.data.data.content.data
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>