<template>
    <div>
        <!-- end newsletter area -->
        <footer v-if="footer_status" class="footer-area">
            <div class="footer-top pt-170 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div class="footer-widget">
                                <div class="navbar-brand">
                                    <a href="/">
                                        <img src="/assets/logo-white.png" style="height:76px" alt="Logo" />
                                    </a>
                                </div>
                                <p v-html="footer_data.footer.description" ></p>
                                <div v-if="social_media_links.length > 0" class="social-link">
                                    <a v-for="(media, index) in social_media_links" :key="index" :href="media.link" target="_blank"><i :class='media.icon'></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="footer-widget">
                                <h3>{{$t('who_we_are')}}</h3>
                                <ul class="footer-links">
                                    <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/company" >{{$t('our_companies')}}</a>
                                    </li>
                                    <!-- <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/projects" >{{$t('our_projects')}}</a>
                                    </li> -->
                                    <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/board-management" >{{$t('board_of_directors')}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="footer-widget">
                                <h3>{{$t('media_gallery')}}</h3>
                                <ul class="footer-links">
                                    <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/news-announcements" >{{$t('news_and_announcements')}}</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/photo-video" >{{$t('photo_and_video')}}</a>
                                    </li>
                                    <!-- <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/social-media">{{$t('social_media')}}</a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="footer-widget">
                                <h3>{{$t('investor_relations')}}</h3>
                                <ul class="footer-links">
                                    <!-- <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/financial-highlights" >{{$t('financial_highlights')}}</a>
                                    </li> -->
                                    <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/annual-reports" >{{$t('annual_reports')}}</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-chevrons-right'></i>
                                        <a href="/corporate-profile" >{{$t('corporate_profile')}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="bg-image"></div>
                </div>
            </div>
            <div class="copy-right-area">
                <div class="container">
                    <div class="copy-right-content">
                        <ul class="info-list">
                            <li class="content">
                                <i class='bx bx-map'></i>{{ footer_data.footer.address }}
                            </li>
                            <li class="content">
                                <i class='bx bx-envelope'></i>
                                <a :href="'mailto:'+footer_data.footer.email">{{footer_data.footer.email}}</a>
                            </li>
                        </ul>
                        <span>
                            {{$t('copyright')}}
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import axios from "../axios";
export default {
    data() {
        return {
            year: new Date().getFullYear(),
            social_media_links: [],
            footer_status: false,
            footer_data: []
        };
    },

    created() {
        axios.get(`homepage`)
        .then(response => {
            this.footer_data = response.data.data.contact
            this.social_media_links = response.data.data.social_media_links
            this.footer_status = response.data.status
            // localStorage.setItem('logo', this.footer_data.footer.logo)

            // const img_logo = document.querySelector("img[class='logo-img']");
            // img_logo.setAttribute("src", this.footer_data.footer.logo)

            // const link = document.querySelector("link[rel*='icon']");
            // link.type = 'image/x-icon';
            // link.rel = 'shortcut icon';
            // link.setAttribute("href", this.footer_data.footer.logo);
            // document.getElementsByTagName('head')[0].appendChild(link);
        })
        .catch(e => {
        })
    }
};
</script>
<style></style>
