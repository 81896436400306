<template>
    <div
        class="home-banner-area home-banner-two"
    >
        <div class="bg-image">
            <video
                width="100%" height="auto"
                id="videoElement"
                @canplay="updatePaused"
                @playing="updatePaused"
                @pause="updatePaused"
                muted="muted"
            >
                <source :src="banner.path" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-10 m-auto">
                <div class="banner-content">
                    <h1 class="mb-0" >
                    {{
                        locale == "ar"
                        ? banner.arabic_title
                        : banner.english_title
                    }}
                    </h1>
                    <h2 class="text-white">
                    {{
                        locale == "ar"
                        ? banner.arabic_sub_title
                        : banner.english_sub_title
                    }}
                    </h2>
                    <div class="btn-group mt-4">
                        <a v-show="paused" @click="play" href="javascript:video(0)" class="youtube-popup video-btn">
                            <i class='bx bx-right-arrow'></i>
                        </a>
                        <a v-show="playing" @click="pause" href="javascript:video(0)" class="youtube-popup video-btn">
                            <i class='bx bx-pause'></i>
                        </a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoSlide",
    props: {
        banner: {
            type: Object
        }
    },
    data(){
        return {
            videoElement: null,
            paused: null,
            locale: localStorage.getItem("locale")
        }
    },
    computed:{
        playing() { return !this.paused; }
    },
    methods: {
        updatePaused(event) {
            this.videoElement = event.target;
            this.paused = event.target.paused;
        },
        play() {
            this.videoElement.play();
        },
        pause() {
            this.videoElement.pause();
        },
    }
}
</script>