<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('contact_us')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start contact section -->
        <section id="contact" class="contact-section contact-single pt-100 pb-70">
            <div class="container">
                <div class="top-content">
                    <div class="title">
                        <span class="sub-title">{{content.small_title}}</span>
                        <h2>{{content.large_title}}</h2>
                    </div>
                    <ul class="info-list">
                        <li><i class='bx bx-phone'></i>{{content.call_title}}</li>
                        <li><a :href="'tel:'+content.phone">{{content.phone}}</a></li>
                    </ul>
                    <ul class="info-list">
                        <li><i class='bx bx-envelope'></i>{{content.mail_title}}</li>
                        <li><a :href="'mailto:'+content.email">{{content.email}}</a></li>
                    </ul>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="contact-image mb-30">
                            <img :src="content.image" />
                        </div>
                    </div>
                    <div class="col-lg-6 mb-30">
                        <form @submit="onSubmit" id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" v-model="form.name" class="form-control" id="name" required="" :placeholder="$t('your_name')" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" v-model="form.email" class="form-control" id="email" required="" :placeholder="$t('email_address')" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" @keypress="isNumber($event)" name="phone_number" v-model="form.phone" class="form-control" id="phone_number" required="" :placeholder="$t('phone_number')" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="subject" v-model="form.subject" class="form-control" id="subject" required="" :placeholder="$t('subject')" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" id="message" v-model="form.message" class="form-control" cols="30" rows="8" required="" :placeholder="$t('your_message_alt')"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="primary-btn">{{$t('submit')}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- end contact section -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: "ContactUs",

    data() {
        return {
            content: [],
            banner_image: '',
            form: {
                name: "",
                email: "",
                phone: "",
                message: "",
                subject: ""
            }
        }
    },

    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        onSubmit(e){
            e.preventDefault()

            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('email', this.form.email);
            formData.append('phone', this.form.phone);
            formData.append('message', this.form.message);
            formData.append("subject", this.form.subject);

            axios.post(`contact-us`, formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(response => {
                if(response.data.status){
                    alert(this.$t('form_submitted'));
                    e.target.reset();
                }else{
                    alert(this.$t('invalid_form_details'));
                }
            })
            .catch(e => {
            })
        }
    },

    created() {
        this.setNavLink('contact_us')
        this.setNavItem('')

        axios.get(`contact-us`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>
