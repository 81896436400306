<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('our_companies')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start service single area -->
        <div class="service-single-area pt-50 pb-100">
            <!-- start services section -->
            <section id="services" class="service-section-three">
                <div class="container">
                    <div class="top-content">
                        <div class="title">
                            <!-- <span class="sub-title">{{$t('dummy_word')}}</span> -->
                            <h2>{{ locale === 'ar' ? data.label_ar : data.label_en }}</h2>
                        </div>
                        <p>{{ locale === 'ar' ? data.sub_label_ar : data.sub_label_en }}</p>
                    </div>
                    <div class="row">
                        <div  v-for="(data, index) in content" :key="index" class="col-lg-4 col-md-6">
                            <div class="item-single mb-30">
                                <div class="image">
                                    <a href="javascript:void(0)" @click="$router.push('/company/'+data.id)"><img :src="data.image" :alt="data.title"></a>
                                </div>
                                <div class="content">
                                    <h3><a href="javascript:void(0)" @click="$router.push('/company/'+data.id)">{{data.title}}</a></h3>
                                </div>
                                <div class="spacer"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>

        <!-- start contact section -->
        <section v-if="contact.content" id="contact" class="contact-section pb-70">
            <div class="container">
                <div class="top-content">
                    <div class="title">
                        <span class="sub-title">{{ contact.content.small_title }}</span>
                        <h2>{{ contact.content.large_title }}</h2>
                    </div>
                    <ul class="info-list">
                        <li><i class='bx bx-phone'></i>{{$t('call_us_now')}}</li>
                        <li><a :href="'tel:' + contact.content.phone">{{contact.content.phone}}</a></li>
                    </ul>
                    <ul class="info-list">
                        <li><i class='bx bx-envelope'></i>{{$t('mail_us_now')}}</li>
                        <li><a :href="'mailto:' + contact.content.email">{{contact.content.email}}</a></li>
                    </ul>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="contact-image mb-30">
                            <img :src="contact.content.image" />
                        </div>
                    </div>
                    <div class="col-lg-6 mb-30">
                        <form @submit="onSubmit" id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input
                                    type="text"
                                    name="name"
                                    v-model="form.name"
                                    class="form-control "
                                    id="name"
                                    required=""
                                    :placeholder="$t('your_name')"
                                    />
                                </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input
                                    type="email"
                                    name="email"
                                    v-model="form.email"
                                    class="form-control "
                                    id="email"
                                    required=""
                                    :placeholder="$t('email_address')"
                                    />
                                </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input
                                    type="text"
                                    @keypress="isNumber($event)"
                                    name="phone_number"
                                    v-model="form.phone"
                                    class="form-control "
                                    id="phone_number"
                                    required=""
                                    :placeholder="$t('phone_number')"
                                    />
                                </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input
                                    type="text"
                                    name="subject"
                                    v-model="form.subject"
                                    class="form-control "
                                    id="subject"
                                    required=""
                                    :placeholder="$t('subject')"
                                    />
                                </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea
                                    name="message"
                                    id="message"
                                    v-model="form.message"
                                    class="form-control "
                                    cols="30"
                                    rows="8"
                                    required=""
                                    :placeholder="$t('your_message_alt')"
                                    ></textarea>
                                </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                <button type="submit" class="primary-btn float-right">
                                    {{ $t("submit") }}
                                </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- end contact section -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'Company',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),

        isNumber: function(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
            },
            onSubmit(e) {
            e.preventDefault();

            let formData = new FormData();
            formData.append("name", this.form.name);
            formData.append("email", this.form.email);
            formData.append("phone", this.form.phone);
            formData.append("message", this.form.message);
            formData.append("subject", this.form.subject);

            axios
                .post(`contact-us`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                if (response.data.status) {
                    alert(this.$t("form_submitted"));
                    e.target.reset();
                } else {
                    alert(this.$t("invalid_form_details"));
                }
                })
                .catch((e) => {});
            },
    },

    data() {
        return {
            data: [],
            content: [],
            contact: [],
            locale: localStorage.getItem("locale"),
            banner_image: '',
            form: {
                name: "",
                email: "",
                phone: "",
                message: "",
                subject: ""
            },
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('our_companies')

        axios.get(`parent-company`)
        .then(response => {
            this.data = response.data.data
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })

        axios
        .get(`homepage`)
        .then((response) => {
            this.contact = response.data.data.contact;
        })
        .catch((e) => {});
    }
}
</script>