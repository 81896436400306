<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('social_media')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start contact section -->
        <section id="contact" class="contact-section contact-single pt-100 pb-70">
            <div class="container">
                <div class="row justify-content-center" >
                    <div class="col-2 text-center p-2" v-for="(data, index) in content" :key="index" >
                        <a :href="data.link" :title="data.title" target="_blank" >
                            <h4 class="mb-3" >{{data.title}}</h4>
                            <i style="font-size: 100px" :class="data.icon+' fa-lg'" ></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- end contact section -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'SocialMedia',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },

    data() {
        return {
            content: [],
            banner_image: ''
        }
    },

    created() {
        this.setNavLink('media_gallery')
        this.setNavItem('social_media')

        axios.get(`social-media-links`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>