<template>
    <div>
        <!-- start page title area -->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('ceo_s_statement')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start about single area -->
        <div class="about-single-area mt-3 ptb-100">
            <!-- end promo area -->
            <div class="container">
                <blockquote class="blockquote">
                    <div class="image">
                        <img width="90" height="90" :src="content.ceo_image" >
                    </div>
                    <p v-html="content.ceo_message" ></p>
                    <span v-html="content.ceo_name" ></span>
                </blockquote>
            </div>
            
        </div>
        <!-- end about single area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'CEOStatement',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },

    data() {
        return {
            content: [],
            banner_image: ''
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('ceo_message')

        axios.get(`about-us`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banners.ceo_message
        })
        .catch(e => {
        })
    }
}
</script>
