<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('join_us_alt')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start career single area -->
        <div class="career-single-area pt-70 pb-70 ">
            <!-- start career section -->
            <section id="career" class="career-section pb-70 d-none">
                <div class="container">
                    <div class="top-content">
                        <div class="title">
                            <span class="sub-title">{{ locale === 'ar' ? data.label_ar : data.label_en }}</span>
                            <h2>{{ locale === 'ar' ? data.sub_label_ar : data.sub_label_en }}</h2>
                        </div>
                        <form id="searchForm" >
                            <div class="form-row">
                                <div class="col-md-10 col-12">
                                    <input type="text" name="name" v-model="search_key" class="form-control" id="name" required :placeholder="$t('enter_job_title')" />
                                </div>
                                <div class="col-md-2 col-6">
                                    <button type="button" @click="search" class="primary-btn">{{$t("search")}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <div v-for="(data, index) in content" :key="index" class="col-lg-4 col-md-6">
                            <div class="item-single mb-30">
                                <h3><a href="javascript:void(0)">{{(locale == 'ar') ? data.arabic_title : data.english_title}}</a></h3>
                                <p>
                                    <read-more :more-str="$t('read_more')" :text="(locale == 'ar') ? data.arabic_short_description : data.english_short_description" link="#" :less-str="$t('read_less')" :max-chars="70"></read-more>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="content-area d-none">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="content mb-30">
                                <h2>{{recruitment_process.title}}</h2>
                                <p v-html="recruitment_process.description" >
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <div class="image mb-30">
                                <img :src="recruitment_process.image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end career section -->
            <hr>
            <!-- start appointment area -->
            <div id="appointment" class="appointment-area mt-30 pb-70">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8">
                            <div class="content">
                                <span class="sub-title">{{ locale === 'ar' ? data.form_label_ar : data.form_label_en }}</span>
                                <h2>{{ locale === 'ar' ? data.form_sub_label_ar : data.form_sub_label_en }}</h2>
                            </div>
                            <form @submit="onSubmit" id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="name" class="form-control" v-model="form.name" id="name" required="" :placeholder="$t('your_name')" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="email" name="email" class="form-control" v-model="form.email" id="email" required="" :placeholder="$t('email_address')" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" @keypress="isNumber($event)" name="phone" class="form-control" v-model="form.phone" id="phone_number" required="" :placeholder="$t('phone_number')" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="job_title" class="form-control" v-model="form.job_title" id="job_title" required="" :placeholder="$t('enter_job_title')" />
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-6 col-md-6 d-none">
                                        <div class="form-group">
                                            <div class="select-box">
                                                <select required="" v-model="form.careers_id" class="form-control">
                                                    <option selected value="">{{$t('select_job')}}</option>
                                                    <option v-for="(data, index) in content" :key="index" :value="data.id">{{(locale == 'ar') ? data.arabic_title : data.english_title}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="input1">{{$t('upload_your_resume')}}</label>
                                            <input type="file" name="file" class="form-control-file" accept="application/pdf" @change="readFile" id="msg_subject" required="" />
                                        </div>
                                    </div>
                                    
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="primary-btn float-right">{{$t('apply')}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end contact area -->
        </div>
        <!-- start career single area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'Career',

    data() {
        return {
            data: [],
            hide_index: 0,
            content: [],
            recruitment_process: [],
            original_content: [],
            banner_image: '',
            file_type: '',
            search_key: '',
            locale: localStorage.getItem("locale"),
            form: {
                name: "",
                email: "",
                phone: "",
                file:[],
                job_title: ''
            }
        }
    },

    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        search(){
            const search = this.search_key.toLowerCase().trim();
            if (!search) {
                this.content = this.original_content;
                return false;
            }
            this.content = this.original_content.filter(item => {
                if(this.locale == 'ar'){
                    return item.arabic_title.toLowerCase().includes(search);
                }else{
                    return item.english_title.toLowerCase().includes(search);
                }
            });
        },
        hideSmallHeading(index){
            if(index != this.hide_index){
                this.hide_index = index
            }else{
                this.hide_index = 0
            }
        },
        readFile(e){
            var file_data =  e.target.files[0]
            var file_type = file_data.type
            if(file_type == 'application/pdf'){
                this.form.file = file_data
            }else{
                this.form.file = null
            }
        },
        onSubmit(e){
            e.preventDefault()

            if(this.form.file != null){
                let formData = new FormData();
                formData.append('name', this.form.name);
                formData.append('email', this.form.email);
                formData.append('phone', this.form.phone);
                formData.append('job_title', this.form.job_title);
                formData.append('file', this.form.file);

                axios.post(`careers-form`, formData, {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    if(response.data.status){
                        alert(this.$t('form_submitted'));
                        e.target.reset();
                    }else{
                        alert(this.$t('invalid_form_details'));
                    }
                })
                .catch(e => {
                })
            }else{
                alert('Please choose a valid PDF file')
            }
        }
    },

    created() {
        this.setNavLink('join_us')
        this.setNavItem('')

        axios.get(`careers-list`)
        .then(response => {
            this.data = response.data.data
            this.original_content = response.data.data.content
            this.content = response.data.data.content
            this.recruitment_process = response.data.data.recruitment_process
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>