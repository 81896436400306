<template>
  <div>
    <template v-if="banners.length > 0">
      <carousel
        dir="ltr"
        :items="1"
        :navContainer="'#homeSlider'"
        :nav="true"
        :loop="true"
        :dots="false"
        :navText="locale == 'ar' ? navTextAr : navText"
        :autoplay="false"
        :autoplayTimeout="2000"
      >
        <template v-for="(banner, index) in banners">
          <template v-if="banner.type == 'image'">
            <div
              id="home"
              :key="index"
              :style="{ backgroundImage: 'url(' + banner.path + ')' }"
              class="home-banner-area home-banner-two"
              data-ride="carousel"
            >
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-10 m-auto">
                    <div class="banner-content">
                      <h1 class="mb-0" >
                        {{
                          locale == "ar"
                            ? banner.arabic_title
                            : banner.english_title
                        }}
                      </h1>
                      <h2 class="text-white">
                        {{
                          locale == "ar"
                            ? banner.arabic_sub_title
                            : banner.english_sub_title
                        }}
                      </h2>
                      <br>
                      <br>
                      <br>
                      <br>
                      <div class="btn-group mt-4">
                          <a href="/history" class="primary-btn">{{$t("discover")}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="banner.type == 'video'">
            <VideoSlide :key="index" :banner="banner" ></VideoSlide>
          </template>
        </template>
      </carousel>
      <div id="homeSlider" class="custom-owl-nav-home"></div>
    </template>

    <section v-if="banners.length > 0" class="promo-section">
      <div class="container">
        <div class="row">
          <div @click="$router.push('/company')" class="col-lg-4 col-md-6 col-sm-6">
            <div class="item-single mb-30">
              <i class="flaticon flaticon-location"></i>
              <h3>
                <a href="/company">{{ $t("our_companies") }}</a>
              </h3>
            </div>
          </div>
          <div @click="$router.push('/history')" class="col-lg-4 col-md-6 col-sm-6">
            <div class="item-single mb-30">
              <img src="/assets/icons/history-icon.png" width="50" />
              <h3>
                <a href="/history">{{ $t("our_history") }}</a>
              </h3>
            </div>
          </div>
          <div @click="$router.push('/news-announcements')" class="col-lg-4 col-md-6 col-sm-6 m-auto">
            <div class="item-single mb-30">
              <img src="/assets/icons/news-icon.png" width="50" />
              <h3>
                <a href="/news-announcements">{{ $t("latest_news") }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="banners.length > 0"
      id="about"
      class="about-section-two pb-70"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-image mb-30">
              <img :src="homepage.image" />
              <div class="overlay-img">
                    <img :src="homepage.image2" class="img1">
                    <img :src="homepage.image3" class="img2">
                </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content mb-30">
              <h2>
                {{ homepage.small_title }}
              </h2>
              <h5>
                {{ homepage.large_title }}
              </h5>
              <p v-html="homepage.description"></p>
              
              <blockquote class="blockquote">
                  <p><q>{{ remove_div(about_data.ceo_short_message) }}</q> </p>
                  <span>- {{ about_data.ceo_name }}</span>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="companies.length > 0" class="service-section-two ptb-70">
      <div class="container">
        <div class="row no-wrap align-items-center">
          <div class="col-lg-4 col-md-12">
            <div class="content mb-30">
              <h2>
                {{ $t("our_companies") }}
              </h2>
              <h5>
                  {{ locale === 'ar' ? companies_labels.label_ar : companies_labels.label_en }}
              </h5>
              <p>
                  {{ locale === 'ar' ? companies_labels.sub_label_ar : companies_labels.sub_label_en }}
              </p>
              <a
                href="javascript:void(0)"
                @click="$router.push('/company')"
                class="primary-btn"
                >{{ $t("see_all_companies") }}</a
              >
              <div id="serviceSlider" class="custom-owl-nav"></div>
            </div>
          </div>
          <div class="col col-md-12">
            <template v-if="companies.length > 0">
              <carousel
                dir="ltr"
                :navContainer="'#serviceSlider'"
                :nav="true"
                :loop="true"
                :dots="false"
                :autoplay="true"
                :autoplayTimeout="5000"
                :autoplayHoverPause="true"
                :navText="locale == 'ar' ? navTextAr : navText"
                :responsive="{
                  0: { items: 1 },
                  768: { items: 2 },
                  991: { items: 3 },
                }"
              >
                <div
                  v-for="(company, index) in companies"
                  :key="index"
                  class="item-single mb-30"
                >
                  <div class="image">
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/company/' + company.id)"
                      ><img :src="company.image" :alt="company.title"
                    /></a>
                  </div>
                  <div class="content">
                    <h3>
                      <a
                        href="javascript:void(0)"
                        @click="$router.push('/company/' + company.id)"
                        >{{ company.title }}</a
                      >
                    </h3>
                    <p>{{remove_div(company.description.substring(0,80)+"..")}}</p>
                    <div class="cta-btn">
                      <a
                        href="javascript:void(0)"
                        @click="$router.push('/company/' + company.id)"
                        class="btn-text"
                        >{{ $t("read_more") }}</a
                      >
                    </div>
                  </div>
                  <div class="spacer"></div>
                </div>
              </carousel>
            </template>
          </div>
        </div>
      </div>
    </section>

    <!-- <section
      v-if="projects.length > 0"
      id="projects"
      class="projects-section-two pb-70 "
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <template v-for="(project, index) in projects">
            <div v-if="index == 2" :key="index" class="col-lg-4 col-md-12">
              <div class="content mb-30">
                <h2>
                  {{ $t("projects") }}
                </h2>
                <h5>
                    {{ locale === 'ar' ? projects_labels.label_ar : projects_labels.label_en }}
                </h5>
                <p>
                    {{ locale === 'ar' ? projects_labels.sub_label_ar : projects_labels.sub_label_en }}
                </p>
                <a href="/projects" class="primary-btn">{{
                  $t("all_projects")
                }}</a>
              </div>
            </div>

            <div :key="index" class="col-lg-4 col-md-6">
              <div class="item-single mb-30">
                <div class="image">
                  <a :href="'/project/' + project.id"
                    ><img :src="project.image" :alt="project.title"
                  /></a>
                </div>
                <div class="content">
                  <h3>
                    <a :href="'/project/' + project.id">{{ project.title }}</a>
                  </h3>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section> -->

    <!-- <section v-if="news.length > 0" id="blog" class="blog-section-two pb-70">
      <div class="container">
        <div class="top-content-two">
          <h2>{{ $t("latest_news") }}</h2>
        </div>
        <div class="row justify-content-center">
          <div
            v-for="(ne, index) in news"
            :key="index"
            class="col-lg-4 col-md-6"
          >
            <div class="item-single mb-30">
              <div class="image">
                <a :href="'/news-details/' + ne.id"><img :src="ne.image"/></a>
              </div>
              <div class="content">
                <ul class="info-list">
                  <li>
                    <i class="bx bx-user"></i>{{ $t("by") }}
                    {{ ne.user != null ? ne.user.name : "" }}
                  </li>
                  <li><i class="bx bx-calendar-alt"></i>{{ format_date(ne.created_at) }}</li>
                </ul>
                <h3>
                  <a :href="'/news-details/' + ne.id">{{ ne.title }}</a>
                </h3>
                <p>
                  {{ ne.short_description }}
                </p>
                <hr />
                <div class="cta-btn">
                  <a :href="'/news-details/' + ne.id" class="btn-text"
                    >{{ $t("read_more") }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section
      v-if="banners.length > 0"
      :style="{ backgroundImage: 'url(' + contact.banner + ')' }"
      id="quote"
      class="quote-section pt-70 pb-70 mb-5"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5">
            <div class="content">
              <h2>
                {{ contact.content.large_title }}
              </h2>
              <h5>
                {{ contact.content.small_title }}
              </h5>
              <p>
                  {{ locale === 'ar' ? contact.label_ar : contact.label_en }}
              </p>
              <ul class="info-list">
                <li>
                  <i class="bx bxs-map"></i
                  ><a href="#">{{ contact.footer.address }}</a>
                </li>
                <li>
                  <i class="bx bx-phone"></i
                  ><a :href="'tel:' + contact.content.phone">{{
                    contact.content.phone
                  }}</a>
                </li>
                <li>
                  <i class="bx bx-envelope"></i
                  ><a :href="'mailto:' + contact.content.email">{{
                    contact.content.email
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-7 mt-5">
            <form @submit="onSubmit" id="contactForm">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="name"
                      v-model="form.name"
                      class="form-control text-white"
                      id="name"
                      required=""
                      :placeholder="$t('your_name')"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      v-model="form.email"
                      class="form-control text-white"
                      id="email"
                      required=""
                      :placeholder="$t('email_address')"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      @keypress="isNumber($event)"
                      name="phone_number"
                      v-model="form.phone"
                      class="form-control text-white"
                      id="phone_number"
                      required=""
                      :placeholder="$t('phone_number')"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="subject"
                      v-model="form.subject"
                      class="form-control text-white"
                      id="subject"
                      required=""
                      :placeholder="$t('subject')"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea
                      name="message"
                      id="message"
                      v-model="form.message"
                      class="form-control text-white"
                      cols="30"
                      rows="8"
                      required=""
                      :placeholder="$t('your_message_alt')"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <button type="submit" class="primary-btn float-right">
                    {{ $t("submit") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.text-max {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 6.3em;
  line-height: 1.8em;
}
</style>
<script>
import axios from "../axios";
import carousel from "vue-owl-carousel";
import { mapActions } from "vuex";
import moment from 'moment';
import VideoSlide from '../components/VideoSlide.vue';

export default {
  components: {
    carousel,
    VideoSlide
  },
  name: "Home",
  methods: {
    ...mapActions("navigation", ["setNavLink"]),
    ...mapActions("navigation", ["setNavItem"]),
    format_date(value){
        if (value) {
          return moment(String(value)).format('DD MMM, YYYY')
        }
    },
    remove_div(string){
      let string_1 = string.replace('<div dir=\"rtl\">', '')
      let string_2 = string_1.replace('<div dir=\"rtl\">', '')
      let string_3 = string_2.replace('&nbsp;', '')
      let string_4 = string_3.replace("<div>", '')
      let string_5 = string_4.replace("</div>", '')
      let string_6 = string_5.replace("</div>", '')
      let string_7 = string_6.replace("<br>", '')
      return string_7.replace('<div dir="rtl">', '')
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onSubmit(e) {
      e.preventDefault();

      let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("email", this.form.email);
      formData.append("phone", this.form.phone);
      formData.append("message", this.form.message);
      formData.append("subject", this.form.subject);

      axios
        .post(`contact-us`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.status) {
            alert(this.$t("form_submitted"));
            e.target.reset();
          } else {
            alert(this.$t("invalid_form_details"));
          }
        })
        .catch((e) => {});
    },
  },
  data() {
    return {
      navText: [
        "<i class='bx bxs-chevron-left'></i>",
        "<i class='bx bxs-chevron-right'></i>",
      ],
      navTextAr: [
        "<i class='bx bxs-chevron-right'></i>",
        "<i class='bx bxs-chevron-left'></i>",
      ],
      banners: [],
      homepage: [],
      companies: [],
      projects: [],
      galleries: [],
      contact: [],
      news: [],
      about_data: [],
      locale: localStorage.getItem("locale"),

      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        subject: ""
      },
    };
  },
  created() {
    this.setNavLink("home");
    this.setNavItem("");

    axios
      .get(`homepage`)
      .then((response) => {
        this.banners = response.data.data.banners;
        this.homepage = response.data.data.homepage;
        this.projects = response.data.data.projects.content;
        this.projects_labels = response.data.data.projects;
        this.contact = response.data.data.contact;
        this.companies = response.data.data.parent_companies.content;
        this.companies_labels = response.data.data.parent_companies;
        this.news = response.data.data.news;
        this.galleries = response.data.data.gallery.content;
        this.about_data = response.data.data.about_us;
      })
      .catch((e) => {});

  },
};
</script>
