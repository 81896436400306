<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{content.title}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="content.banner_path" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start blog details section -->
        <section v-if="content.image" class="blog-details-section pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="blog-details-desc mb-30">
                            <div class="item-single mb-30">
                                <div class="image">
                                    <img :src="content.image" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <aside class="widget-area">
                            <h2>{{content.short_description}}</h2>
                        </aside>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="blog-details-desc mb-30">
                            <div class="item-single mb-30">
                                <div class="item-content">
                                    <ul class="info-list">
                                        <li><i class='bx bx-user'></i>{{$t('by')}} {{(content.user != null) ? content.user.name : ''}}</li>
                                        <li><i class='bx bx-calendar-alt'></i>{{format_date(content.created_at)}}</li>
                                    </ul>
                                    <div class="content mt-3">
                                        <p v-html="content.long_description">
                                        </p>
                                    </div>
                                    <hr>
                                    <ul class="list">
                                        <li>
                                            <span class="mr-1"><b>{{$t("tags_alt")}}</b></span>
                                            <span class="tags">{{content.tags}}</span>
                                        </li>
                                        <li>
                                            <div class="social-link">
                                                <ShareNetwork
                                                    network="facebook"
                                                    :url="current_route"
                                                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                                    hashtags="vuejs,vite"
                                                >
                                                    <i class="bx bxl-facebook"></i>
                                                </ShareNetwork>
                                                <ShareNetwork
                                                    network="twitter"
                                                    :url="current_route"
                                                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                                    hashtags="vuejs,vite"
                                                >
                                                    <i class="bx bxl-twitter"></i>
                                                </ShareNetwork>
                                                <ShareNetwork
                                                    network="linkedin"
                                                    :url="current_route"
                                                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                                    hashtags="vuejs,vite"
                                                >
                                                    <i class="bx bxl-linkedin"></i>
                                                </ShareNetwork>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end blog details section -->

    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from "vuex";
import moment from 'moment';

export default {
    name: "NewsDetails",

    methods: {
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
        format_date(value){
            if (value) {
                return moment(String(value)).format('DD MMM, YYYY')
            }
        },
    },
    
    data() {
        return {
            news_id: this.$route.params.id,
            current_route: window.location.href,
            content: []
        }
    },

    created() {
        this.setNavLink('media_gallery')
        this.setNavItem('news_and_announcements')
        
        axios.get(`news/${this.news_id}`)
        .then(response => {
            this.content = response.data.data
        })
        .catch(e => {
        })
    }
}
</script>
