<style scoped>
    .navbar-brand .logo-img{
        height: 63px;
    }
</style>
<template>
  <header class="header-area header-two">
     
      <!-- start navbar area -->
      <div class="main-navbar-area stickyadd">
          <div class="main-responsive-nav">
              <div class="container">
                  <div class="main-responsive-menu">
                      <div class="logo">
                          <a href="/">
                              <img class="logo-img" src="/assets/logo-dark.png" alt="logo">
                          </a>
                      </div>
                  </div>
              </div>
          </div>

          <div class="main-nav">
              <div class="container">
                  <nav class="navbar navbar-expand-md navbar-light">
                      <a class="navbar-brand" href="/" >
                          <img class="logo-img" src="/assets/logo-light.png">
                          <img class="logo-img" src="/assets/logo-dark.png">
                      </a>
                      <div class="collapse navbar-collapse mean-menu">
                          <ul class="navbar-nav ml-auto">
                              <li class="nav-item">
                                  <a href="/" :class="(nav_link == 'home') ? 'active' : ''" class="nav-link">{{$t('home')}}</a>
                              </li>
                              <li class="nav-item">
                                  <a href="#" :class="(nav_link == 'who_we_are') ? 'active' : ''" class="nav-link toggle">{{$t('who_we_are')}}<i class='bx bxs-chevron-down'></i></a>
                                  <ul class="dropdown-menu">
                                      
                                      <li class="nav-item">
                                          <a href="/company" :class="(nav_item == 'our_companies') ? 'active' : ''" class="nav-link">{{$t('our_companies')}}</a>
                                      </li>
                                      <!-- <li class="nav-item">
                                          <a href="/projects" :class="(nav_item == 'our_projects') ? 'active' : ''" class="nav-link">{{$t('our_projects')}}</a>
                                      </li> -->
                                      <li class="nav-item">
                                          <a href="/history" :class="(nav_item == 'our_history') ? 'active' : ''" class="nav-link">{{$t('our_history')}}</a>
                                      </li>
                                      <li class="nav-item">
                                          <a href="/ceo-statement" :class="(nav_item == 'ceo_message') ? 'active' : ''" class="nav-link">{{$t('ceo_message')}}</a>
                                      </li>
                                      <li class="nav-item">
                                          <a href="/mission-vision" :class="(nav_item == 'mission_vision_values') ? 'active' : ''" class="nav-link">{{$t('mission_vision_values')}}</a>
                                      </li>
                                      <li class="nav-item">
                                          <a href="/board-management" :class="(nav_item == 'board_of_directors') ? 'active' : ''" class="nav-link">{{$t('board_of_directors')}}</a>
                                      </li>
                                  </ul>
                              </li>
                              <li class="nav-item">
                                    <a href="#" :class="(nav_link == 'media_gallery') ? 'active' : ''" class="nav-link toggle">{{$t('media_gallery')}}<i class='bx bxs-chevron-down'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="/news-announcements" :class="(nav_item == 'news_and_announcements') ? 'active' : ''" class="nav-link">{{$t('news_and_announcements')}}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="/photo-video" :class="(nav_item == 'photo_and_video') ? 'active' : ''" class="nav-link">{{$t('photo_and_video')}}</a>
                                        </li>
                                    </ul>
                              </li>
                              <li class="nav-item">
                                  <a href="#" :class="(nav_link == 'investor_relations') ? 'active' : ''" class="nav-link toggle">{{$t('investor_relations')}}<i class='bx bxs-chevron-down'></i></a>
                                  <ul class="dropdown-menu">
                                      <!-- <li class="nav-item">
                                          <a href="/financial-highlights" :class="(nav_item == 'financial_highlights') ? 'active' : ''" class="nav-link">{{$t('financial_highlights')}}</a>
                                      </li> -->
                                      <li class="nav-item">
                                          <a href="/annual-reports" :class="(nav_item == 'annual_reports') ? 'active' : ''" class="nav-link">{{$t('annual_reports')}}</a>
                                      </li>
                                      <li class="nav-item">
                                          <a href="/corporate-profile" :class="(nav_item == 'corporate_profile') ? 'active' : ''" class="nav-link">{{$t('corporate_profile')}}</a>
                                      </li>
                                  </ul>
                              </li>
                              <li class="nav-item">
                                  <a href="/career" :class="(nav_link == 'join_us') ? 'active' : ''" class="nav-link">{{$t('join_us_alt')}}</a>
                              </li>
                              <li class="nav-item">
                                  <a href="/contact" :class="(nav_link == 'contact_us') ? 'active' : ''" class="nav-link">{{$t('contact_us_alt')}}</a>
                              </li>
                              <li class="nav-item">
                                  <a v-if="locale=='ar'" :href="'/lang?lang=en&route='+router_name" class="nav-link">ENG</a>
                                  <a v-else :href="'/lang?lang=ar&route='+router_name" class="nav-link">العربية</a>
                              </li>
                          </ul>
                      </div>
                  </nav>
              </div>
          </div>
      </div>
      <!-- end navbar area -->
  </header>
</template>

<script>

import Vue from 'vue';
import axios from "../axios";
import { mapState } from 'vuex'

export default {
    data() {
        return {
            logo: localStorage.getItem('logo'),
            locale: localStorage.getItem('locale'),
            nav_item: '',
            nav_link: '',
            router_name: this.$route.path
        };
    },
    computed:{
        ...mapState({
            navLink: state => state.navigation.navLink,
            navItem: state => state.navigation.navItem
        }),
    },
    methods:{
        installMeanMenu: function() {
            $('.mean-menu').meanmenu({
                meanScreenWidth: '1199',
            });
        },
        loadLocale: function(){
            if(this.locale == "ar"){
                this.$i18n.locale = "ar";
                let file = document.createElement('link');
                file.rel = 'stylesheet';
                file.href = '/assets/css/rtl.css'
                document.head.appendChild(file)
            }else{
                this.$i18n.locale = "en";
            }
        },
        getLogo(){
            axios.get(`footer`)
            .then(response => {
                this.logo = response.data.data.footer.logo
                const link = document.querySelector("link[rel*='icon']");
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.setAttribute("href", this.logo);
                document.getElementsByTagName('head')[0].appendChild(link);
            })
            .catch(e => {
            })
        }
        
    },
    mounted: function() {
        this.$i18n.locale = this.locale
        Vue.nextTick(function () {
            this.loadLocale();
            this.installMeanMenu();
            // if(this.logo == null){
            //      this.getLogo()
            // }
        }.bind(this))
    },
    watch:{
        navLink(data){
            this.nav_link = data
        },
        navItem(data){
            this.nav_item = data
        }
    }
};
</script>