
<script>
export default {
    name: 'Lang',
    data() {
        return {
            locale: this.$route.query.lang,
            route: this.$route.query.route
        }
    },
    mounted(){
        this.updateLocale()
    },
    methods:{
        updateLocale(){ 
            if(this.route != '/home'){
                localStorage.setItem('locale', this.locale)
                window.location.href = this.route
            }else{
                localStorage.setItem('locale', this.locale)
                window.location.href = '/'
            }
        }
    }
}
</script>