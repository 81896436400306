import Vue from "vue";
import Router from "vue-router";
import Index from "./pages/Index.vue";
import Navbar from "./layout/Navbar.vue";
import Footer from "./layout/Footer.vue";
import Company from "./pages/Company.vue";
import Projects from "./pages/Projects.vue";
import ProjectDetails from "./pages/ProjectDetails.vue";
import CompanyChild from "./pages/CompanyChild.vue";
import CompanyDetails from "./pages/CompanyDetails.vue";
import History from "./pages/History.vue";
import CEOStatement from "./pages/CEOStatement.vue";
import MissionVision from "./pages/MissionVision.vue";
import BoardManagement from "./pages/BoardManagement.vue";
import ManagementTeam from "./pages/ManagementTeam.vue";
import NewsAnnouncements from "./pages/NewsAnnouncements.vue";
import NewsDetails from "./pages/NewsDetails.vue";
import PhotoVideo from "./pages/PhotoVideo.vue";
import SocialMedia from "./pages/SocialMedia.vue";
import FinancialHighlights from "./pages/FinancialHighlights.vue";
import AnnualReports from "./pages/AnnualReports.vue";
import CorporateInfo from "./pages/CorporateInfo.vue";
import Career from "./pages/Career.vue";
import Contact from "./pages/Contact.vue";
import Lang from "./pages/Lang.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "open",
  routes: [
    {
      path: "/",
      name: "Home",
      components: { default: Index, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/lang",
      name: "Lang",
      components: { default: Lang, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/projects",
      name: "Projects",
      components: { default: Projects, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/project/:id",
      name: "ProjectDetails",
      components: { default: ProjectDetails, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/company",
      name: "Company",
      components: { default: Company, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/company/:id",
      name: "ChildCompanies",
      components: { default: CompanyChild, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/company-details/:id",
      name: "CompanyDetails",
      components: { default: CompanyDetails, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/history",
      name: "History",
      components: { default: History, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/ceo-statement/",
      name: "CEOStatement",
      components: { default: CEOStatement, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/mission-vision/",
      name: "MissionVision",
      components: { default: MissionVision, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/board-management/",
      name: "BoardManagement",
      components: { default: BoardManagement, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/management-team/",
      name: "ManagementTeam",
      components: { default: ManagementTeam, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/news-announcements/",
      name: "NewsandAnnouncement",
      components: {
        default: NewsAnnouncements,
        header: Navbar,
        footer: Footer,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/news-details/:id",
      name: "NewsDetails",
      components: {
        default: NewsDetails,
        header: Navbar,
        footer: Footer,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/photo-video/",
      name: "PhotoandVideo",
      components: { default: PhotoVideo, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/social-media/",
      name: "SocialMedia",
      components: { default: SocialMedia, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/financial-highlights/",
      name: "FinancialHighlights",
      components: {
        default: FinancialHighlights,
        header: Navbar,
        footer: Footer,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/annual-reports/",
      name: "AnnualReports",
      components: { default: AnnualReports, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/corporate-profile/",
      name: "CorporateProfile",
      components: { default: CorporateInfo, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/career/",
      name: "Career",
      components: { default: Career, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/contact/",
      name: "ContactUs",
      components: { default: Contact, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/privacy-policy/",
      name: "PrivacyPolicy",
      components: { default: PrivacyPolicy, header: Navbar, footer: Footer },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
