<template>
    <div :class="is_mobile ? 'mobile' : ''" >
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('financial_highlights')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start blog section -->
        <section v-if="content.length > 0" id="blog" class="service-details-area ptb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
						<carousel
                            dir="ltr"
                            :items="1"
                            :navContainer="'#homeSlider'"
                            :nav="true"
                            :loop="true"
                            :dots="false"
                            :navText="locale == 'ar' ? navTextAr : navText"
                            :autoplay="false"
                            :autoplayTimeout="2000"
                        >
                            <div v-for="(data, index) in content" :key="index" class="container">
                                <div class="row">
                                    <div class="col-lg-10 m-auto">
                                        <div class="service-details-desc">
                                            <div class="service-details-slider">
                                                <div class="image">
                                                    <img :src="data.file" >
                                                </div>
                                            </div>
                                            <div class="content">
                                                <h2>{{data.description}}</h2>

                                                <p v-if="data.file">
                                                    <a :href="data.file" class="mr-3" ><i class='fa fa-2x fa-eye'></i></a>
                                                    <a :href="generateDownloadURL(data.file, data.description)" target="_blank"><i class='fa fa-2x fa-download'></i></a>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </carousel>
                        <div id="homeSlider" class="custom-owl-nav-home"></div>
					</div>
                </div>
            </div>
        </section>
        <!-- end blog section -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';
import carousel from "vue-owl-carousel";

export default {
    name: 'FinancialHighlights',
    components: {
        carousel,
    },
    data() {
        return {
            is_mobile: screen.width <= 760 ? true : false,
            content: [],
            banner_image: '',
            navText: [
                "<i class='bx bxs-chevron-left'></i>",
                "<i class='bx bxs-chevron-right'></i>",
            ],
            navTextAr: [
                "<i class='bx bxs-chevron-right'></i>",
                "<i class='bx bxs-chevron-left'></i>",
            ],
            locale: localStorage.getItem("locale"),
        }
    },

    methods: {
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
        generateDownloadURL(url, label) {
            let ext = url.split('.').pop()
            let baseUrl = `${process.env.VUE_APP_BASE_URL}download?`
            return `${baseUrl}url=${url}&label="${label}.${ext}`
        } 
    },

    created() {
        this.setNavLink('investor_relations')
        this.setNavItem('financial_highlights')

        axios.get(`financial-reports`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>
