
export default {
  namespaced: true,
  state: {
    navLink: '',
    navItem: ''
  },
  getters: {
    navLink: state => state.navLink,
    navItem: state => state.navItem
  },
  mutations: {
    setNavLink(state, data) {
      state.navLink = data;
    },
    setNavItem(state, data) {
      state.navItem = data;
    }
  },
  actions: {
    setNavLink({ commit }, data) {
        commit("setNavLink", data);
    },
    setNavItem({ commit }, data) {
        commit("setNavItem", data);
    }
  }
};
