<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('annual_reports')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start blog section -->
        <section id="blog" class="blog-section ptb-70">
            <div class="container">
                <div class="row">
                    <div v-for="(data, index) in content" :key="index" class="col-6 col-md-3">
                        <aside class="widget-area">
                            <div class="widget brochure">
                                <h3 class="title">{{data.year}}</h3>
                                <p>{{data.description}}</p>
                                <div v-if="data.file_q1" class="content-list">
                                    <a :href="generateDownloadURL(data.file_q1, data.description)" class="btn-block">
                                        <i class='bx bxs-file-pdf'></i>
                                        {{$t("download_q1")}}
                                    </a>
                                </div>
                                <div v-if="data.file_q2" class="content-list">
                                    <a :href="generateDownloadURL(data.file_q2, data.description)" class="btn-block">
                                        <i class='bx bxs-file-doc' ></i>
                                        {{$t("download_q2")}}
                                    </a>
                                </div>
                                <div v-if="data.file_q3" class="content-list">
                                    <a :href="generateDownloadURL(data.file_q3, data.description)" class="btn-block">
                                        <i class='bx bxs-file-txt'></i>
                                        {{$t("download_q3")}}
                                    </a>
                                </div>
                                <div v-if="data.file_q4" class="content-list">
                                    <a :href="generateDownloadURL(data.file_q4, data.description)" class="btn-block">
                                        <i class='bx bxs-file-txt'></i>
                                        {{$t("download_q4")}}
                                    </a>
                                </div>
                                <div v-if="data.file_annual" class="content-list">
                                    <a :href="generateDownloadURL(data.file_annual, data.description)" class="btn-block">
                                        <i class='bx bxs-file-txt'></i>
                                        {{$t("download_annual")}}
                                    </a>
                                </div>                                 
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
        <!-- end blog section -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'AnnualReports',
    
    data() {
        return {
            content: [],
            banner_image: ''
        }
    },

    methods: {
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
        generateDownloadURL(url, label) {
            let ext = url.split('.').pop()
            let baseUrl = `${process.env.VUE_APP_BASE_URL}download?`
            return `${baseUrl}url=${url}&label="${label}.${ext}`
        } 
    },

    created() {
        this.setNavLink('investor_relations')
        this.setNavItem('annual_reports')

        axios.get(`annual-reports`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>