<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('management_team')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start team single area -->
        <div class="team-single-area ptb-70">
            <!-- start team section -->
            <section id="team" class="team-section-two">
                <div class="container">
                    <div class="row justify-content-center">
                        <div v-for="(row, index) in content" :key="index" class="col-lg-4 col-md-6">
                            <div class="item-single mb-30">
                                <div class="image">
                                    <a href="javascript:void(0)"><img :src="row.image" :alt="row.name"></a>
                                </div>
                                <div class="content">
                                    <h3><a href="javascript:void(0)">{{row.name}}</a></h3>
                                    <span>{{row.designation}}</span>
                                    <div class="social-link">
                                        <a href="#" target="_blank"><i class='bx bx-mail-send'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- end team section -->
        </div>
        <!-- end team single area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'ManagementTeam',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },

    data() {
        return {
            content: [],
            banner_image: ''
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('management_team')

        axios.get(`about-us`)
        .then(response => {
            this.banner_image = response.data.data.banners.board_of_directors
            response.data.data.content.board_management.forEach((req) => {
                if (req.type === "management") {
                    this.content.push(req)
                } 
            })
        })
        .catch(e => {
        })
    }
}
</script>


