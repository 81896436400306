<template>
    <div>
         <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('privacy_policy')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start policy section -->
        <section id="policy" class="mt-1 pt-100 pb-70">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div class="text-inside text-justify">
                            <p> At AL-NAWADI HOLDING CO. KSCC, accessible from www.alnawadiholding.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by AL-NAWADI HOLDING CO. KSCC and how we use it.<br>
                            <br>
                                If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at info@alnawadiholding.com</p>
                            <hr>
                            <div class="spacer-small"></div>
                            
                            <h4>What information do we collect? </h4>        
                                <p>We collect some personal information, such as Full Name, Contact Information, CIVIL ID, etc. from you when you register on our site or fill out our form. When ordering or registering on our application, you may be asked to enter the required information to complete the operational process. However, a user may visit the website anonymously. 
                            
                            </p>
                            
                            <h4>What do we use your information for? </h4>
                                <p>The information we collect from you may be used for one or all of the following:
                            </p><ul class="list00">
                                <li>To personalize your usability experience  </li>
                                <li>To improve Platinum Health Club as a product and service provider</li>
                                <li>To improve our customer service </li>
                                <li>To process transactions: Your information, whether private or public, will NOT be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested by the customer. </li>
                                <li>To send periodic emails: The email address we collect from you might be used to send you information and updates pertaining to your order. Furthermore, it might also be used to send news about the company, related product or service information, etc. </li>
                                <li>To administer a contest, promotion, survey or other application feature </li>
                            </ul>
                                <br>
                                Al Nawadi Holding follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                                <br>
                            OR
                                <br>
                                We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
                                <br>
                                We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g. to ship an order.
                                <br>
                                Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.
                            <p></p>

                            <h4>How do we protect your information? </h4>
                                <p>
                                We implement a variety of security measures to maintain the safety of your personal information when we access your personal information.               
                                <br>
                                We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.
                                <br>
                                Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the Web page.
                                <br>
                                While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
                            </p>

                            <h4>Do we use cookies?</h4>
                            <p>
                                Like any other website, AlNawadi uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
                                <br>
                                You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                                </p>

                            <h4>Do we disclose your information to outside parties?</h4>
                            <p>
                                We do not sell, trade, or otherwise transfer to outside parties your personal identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect mutual rights, property or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other users. 
                            </p>

                            <h4>Third-party links? </h4>
                            <p>
                                Occasionally, at our discretion, we may include or offer third party products or services on our website.  These third-party sites might have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. 
                                <br>
                                AlNawadi's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here https://www.alnawadiholding.com/privacypolicy.php

                                </p>

                            <h4>Terms and Conditions </h4>
                            <p>
                                Please visit our Terms and Conditions section establishing the use, disclaimers and limitations of liability governing the use of our website at www.alnawadiholding.com/tnc
                            </p>
                            <h4>Your Consent </h4>
                            <p>
                                By using our site, you consent to our terms and conditions
                            </p>
                                <h4>Changes to our Privacy Policy </h4>
                            <p>
                                If we decide to change our privacy policy, we will post those changes on this page.
                                This policy was last modified on 2nd August 2020
                            </p>
                                <h4>Contacting Us </h4>
                            <p>
                                For any questions regarding this privacy policy you may contact us using the information below
                                http://www.alnawadiholding.com/
                                Kuwait
                                info@alnawadiholding.com
                            </p>


                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end about section -->
    </div>
</template>

<script>
    import axios from "../axios";
    import { mapActions } from 'vuex';
    
    export default {
        name: 'History',
        methods:{
            ...mapActions("navigation", ["setNavLink"]),
            ...mapActions("navigation", ["setNavItem"]),
            remove_div(string){
                let string_1 = string.replace('<div dir=\"rtl\">', '')
                let string_2 = string_1.replace('<div dir=\"rtl\">', '')
                let string_3 = string_2.replace('&nbsp;', '')
                let string_4 = string_3.replace("<div>", '')
                let string_5 = string_4.replace("</div>", '')
                let string_6 = string_5.replace("</div>", '')
                let string_7 = string_6.replace("<br>", '')
                return string_7.replace('<div dir="rtl">', '')
            },
        },
    
        data() {
            return {
                content: [],
                banner_image: '',
                about_data: []
            }
        },
    
        created() {
            this.setNavLink('who_we_are')
            this.setNavItem('our_history')
    
            axios.get(`our-history`)
            .then(response => {
                this.content = response.data.data.content
                this.banner_image = response.data.data.banner_image
            })
            .catch(e => {
            })
    
        }
    }
</script>
    