<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('corporate_profile')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start faq area -->
        <div id="faq" class="faq-area pt-70 pb-70">
            <div class="container">
                <div class="content-area">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="content mb-30">
                                    <h2>{{ locale === 'ar' ? data.label_ar : data.label_en }}</h2>
                                    <div>{{ locale === 'ar' ? data.sub_label_ar : data.sub_label_en }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3" >
                                <div class="item-single mb-30">
                                    <div class="image">
                                        <a :href="content.file"><img :src="content.image" :alt="content.title"/></a>
                                    </div>
                                    <div class="content">
                                        <b>
                                            {{$t('corporate_profile')}}
                                        </b>
                                        <h3 v-if="content.file" :class="locale === 'ar' ? 'float-left' : 'float-right'" >
                                            <a :href="generateDownloadURL(content.file, content.title)" target="_blank"><i class='fa fa-download'></i></a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end faq area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'CorporateProfile',
    
    data() {
        return {
            data: [],
            content: [],
            locale: localStorage.getItem("locale"),
            banner_image: ''
        }
    },

    methods: {
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
        generateDownloadURL(url, label) {
            let ext = url.split('.').pop()
            let baseUrl = `${process.env.VUE_APP_BASE_URL}download?`
            return `${baseUrl}url=${url}&label="${label}.${ext}`
        } 
    },

    created() {
        this.setNavLink('investor_relations')
        this.setNavItem('corporate_profile')

        axios.get(`corporate-information`)
        .then(response => {
            this.data = response.data.data
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>