<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('photo_and_video')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <section id="projects" class="projects-section ptb-70">
            <div class="container">
                <div class="projects-wrapper">
                    <div class="row">
                        <div v-for="(data, index) in content" :key="index" class="col-lg-4 col-md-6">
                            <div class="item-single mb-20">
                                <template v-if="data.type == 'image'" >
                                    <div class="image">
                                        <img :src="data.path" :alt="data.title">
                                    </div>
                                </template>
                                <template v-else-if="data.type == 'video'" >
                                    <video controls width="100%" height="auto" >
                                        <source :src="data.path" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </template>
                                <div v-if="data.type == 'image'" class="content">
                                    <h3><a href="javascript:void(0)">{{(locale == 'ar') ? data.arabic_title : data.english_title}}</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: "PhotoandVideo",
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },

    data() {
        return {
            content: [],
            banner_image: '',
            locale: localStorage.getItem("locale")
        }
    },

    created() {
        this.setNavLink('media_gallery')
        this.setNavItem('photo_and_video')

        axios.get(`photos-videos`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>
