<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('board_of_directors')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start team single area -->
        <div class="team-single-area pt-50 pb-100">
            <section id="director" class="director-section pb-70">
                <div class="container">
                    <div class="top-content">
                        <div class="title">
                            <!-- <span class="sub-title">{{$t('dummy_word')}}</span> -->
                            <h2>{{ locale === 'ar' ? labels.board_of_directors_label_ar : labels.board_of_directors_label_en }}</h2>
                        </div>
                        <p>{{ locale === 'ar' ? labels.board_of_directors_sub_label_ar : labels.board_of_directors_sub_label_en }}</p>
                    </div>
                    <div class="row">
                        <div v-for="(row, index) in board_of_directors" :key="index" class="col-lg-4 col-md-6">
                            <div class="item-single mb-30">
                                <div class="image">
                                    <a href="javascript:void(0)"><img :src="row.image" :alt="row.name"></a>
                                </div>
                                <div class="content">
                                    <h3><a href="javascript:void(0)">{{row.name}}</a></h3>
                                    <span>{{row.designation}}</span>
                                    <p v-html="row.bio" ></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="team" class="team-section-two">
                <div class="container">
                    <div class="top-content">
                        <div class="title">
                            <!-- <span class="sub-title">{{$t('dummy_word')}}</span> -->
                            <h2>{{ locale === 'ar' ? labels.management_team_label_ar : labels.management_team_label_en }}</h2>
                        </div>
                        <p>{{ locale === 'ar' ? labels.management_team_sub_label_ar : labels.management_team_sub_label_en }}</p>
                    </div>
                    <div class="row">
                        <div v-for="(row, index) in management_team" :key="index" class="col-lg-3 col-md-6">
                            <div class="item-single mb-30">
                                <div class="image">
                                    <a href="javascript:void(0)"><img :src="row.image" :alt="row.name"></a>
                                </div>
                                <div class="content">
                                    <h3><a href="javascript:void(0)">{{row.name}}</a></h3>
                                    <span>{{row.designation}}</span>
                                    <div class="social-link">
                                        <a href="#" target="_blank"><i class='bx bx-mail-send'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <!-- end team single area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'BoardManagement',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },

    data() {
        return {
            labels: [],
            locale: localStorage.getItem("locale"),
            board_of_directors: [],
            management_team: [],
            banner_image: ''
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('board_of_directors')

        axios.get(`about-us`)
        .then(response => {
            this.banner_image = response.data.data.banners.board_of_directors
            
            this.labels = response.data.data.labels

            response.data.data.content.board_management.forEach((req) => {
                if (req.type === "board") {
                    this.board_of_directors.push(req)
                } 
            })

            response.data.data.content.board_management.forEach((req) => {
                if (req.type === "management") {
                    this.management_team.push(req)
                } 
            })
        })
        .catch(e => {
        })
    }
}
</script>


