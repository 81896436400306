<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('news_and_announcements')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start blog section -->
        <section id="blog" class="blog-section ptb-100">
            <div class="container">
                <div v-if="data.label_ar && data.sub_label_ar" class="top-content">
                    <div v-if="data.label_ar" class="title">
                        <!-- <span class="sub-title">{{$t('dummy_word')}}</span> -->
                        <h2>{{ locale === 'ar' ? data.label_ar : data.label_en }}</h2>
                    </div>
                    <p v-if="data.sub_label_ar">{{ locale === 'ar' ? data.sub_label_ar : data.sub_label_en }}</p>
                </div>
                <div class="row">
                    <template v-for="(row, index) in content" >
                        <div :key="index" v-if="row.status"  @click="$router.push('/news-details/'+row.id)" class="col-lg-4 col-md-6">
                            <div class="item-single mb-30">
                                <div class="image">
                                    <a href="javascript:void(0)"><img :src="row.image" /></a>
                                </div>
                                <div class="content">
                                    <h3 class="text-truncate">
                                        {{row.title}}
                                    </h3>
                                    <ul class="info-list">
                                        <li><i class='bx bx-user'></i>{{$t('by')}} {{(row.user != null) ? row.user.name : ''}}</li>
                                        <li><i class='bx bx-calendar-alt'></i>{{row.created_at}}</li>
                                    </ul>
                                    <hr>
                                    <read-more :more-str="''" :text="row.short_description" link="#" :less-str="''" :max-chars="80"></read-more>
                                    <div class="cta-btn">
                                        <a href="javascript:void(0)" @click="$router.push('/news-details/'+row.id)" class="btn-text">{{$t('read_more')}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </section>
        <!-- end service single area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'NewsandAnnouncement',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },

    data() {
        return {
            data: [],
            locale: localStorage.getItem("locale"),
            content: [],
            banner_image: ''
        }
    },

    created() {
        this.setNavLink('media_gallery')
        this.setNavItem('news_and_announcements')

        axios.get(`news`)
        .then(response => {
            this.data = response.data.data
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })
    }
}
</script>