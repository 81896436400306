<template>
    <div>
         <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('our_history')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start about section -->
        <section id="about" class="about-section-two mt-1 pt-100 pb-70">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="about-image mb-30">
                            <img :src="content.image1" :alt="content.title">
                            <div class="overlay-img">
                                <img :src="content.image2" :alt="content.title" class="img1">
                                <img :src="content.image3" :alt="content.title" class="img2">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div v-html="content.description" class="about-content mb-30">
                            
                        </div>
                        <blockquote class="blockquote">
                            <p><q>{{ remove_div(about_data.content.ceo_short_message) }}</q> </p>
                            <span>- {{ about_data.content.ceo_name }}</span>
                        </blockquote>
                    </div>
                </div>
            </div>
        </section>
        <!-- end about section -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: 'History',
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
        remove_div(string){
            let string_1 = string.replace('<div dir=\"rtl\">', '')
            let string_2 = string_1.replace('<div dir=\"rtl\">', '')
            let string_3 = string_2.replace('&nbsp;', '')
            let string_4 = string_3.replace("<div>", '')
            let string_5 = string_4.replace("</div>", '')
            let string_6 = string_5.replace("</div>", '')
            let string_7 = string_6.replace("<br>", '')
            return string_7.replace('<div dir="rtl">', '')
        },
    },

    data() {
        return {
            content: [],
            banner_image: '',
            about_data: []
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('our_history')

        axios.get(`our-history`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banner_image
        })
        .catch(e => {
        })

        axios.get(`about-us`)
        .then(response => {
            this.about_data = response.data.data
        })
        .catch(e => {
        })
    }
}
</script>
