<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{data.title}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="data.banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <!-- start service details area -->
        <div class="service-details-area pt-100 pb-70">
            <div class="container">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="image mb-30">
                            <img :src="data.image" :alt="data.title">
                        </div>
					</div>
					<div class="col-lg-8 col-md-12">
						<div class="service-details-desc">
							<div class="content" v-html="data.description">
                                
                            </div>
                        </div>
					</div>
				</div>
            </div>
        </div>
        <!-- end service details area -->
    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: "CompanyDetails",
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },
    
    data() {
        return {
            company_id: this.$route.params.id,
            data: []
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('our_companies')
        
        axios.get(`child-company/${this.company_id}`)
        .then(response => {
            this.data = response.data.data
        })
        .catch(e => {
        })
    }
}
</script>
