/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import Vuex from 'vuex'
import App from './App.vue';
import router from './router';
import i18n from "./i18n";
import store from "./store";
import VueSocialSharing from 'vue-social-sharing';
import ReadMore from 'vue-read-more';

Vue.use(VueSocialSharing)
Vue.use(ReadMore)
Vue.use(Vuex)

Vue.config.productionTip = false;

new Vue({
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');
