<template>
    <div>
        <!-- start page title area-->
        <div class="page-title-area ptb-100">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{$t('mission_vision_values')}}</h1>
                </div>
			</div>
			<div class="bg-image">
				<img :src="banner_image" >
			</div>
        </div>
        <!-- end page title area -->

        <div v-if="content.mission" class="promo-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="promo-inner">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="content">
                                        <h2>
                                            {{$t('our_mission')}}
                                        </h2>
                                        <p v-html="content.mission">
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="image">
                                        <img :src="content.image_mission">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="promo-inner">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="image">
                                        <img :src="content.image_vision" >
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="content">
                                        <h2>
                                            {{$t('our_vision')}}
                                        </h2>
                                        <p v-html="content.vision" >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="promo-inner">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="content">
                                        <h2>
                                            {{$t('our_values')}}
                                        </h2>
                                        <p v-html="content.values" >
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="image">
                                        <img :src="content.image_values" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "../axios";
import { mapActions } from 'vuex';

export default {
    name: "MissionVision",
    methods:{
        ...mapActions("navigation", ["setNavLink"]),
        ...mapActions("navigation", ["setNavItem"]),
    },

    data() {
        return {
            content: [],
            banner_image: ''
        }
    },

    created() {
        this.setNavLink('who_we_are')
        this.setNavItem('mission_vision_values')

        axios.get(`about-us`)
        .then(response => {
            this.content = response.data.data.content
            this.banner_image = response.data.data.banners.mission_vision
        })
        .catch(e => {
        })
    }
}
</script>


